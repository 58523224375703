var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropdown),expression:"closeDropdown"}],class:[
    'app-languages',
    { 'app-languages_focused': _vm.dropdownIsOpened }
  ]},[_c('div',{staticClass:"app-languages__panel",on:{"click":function($event){_vm.dropdownIsOpened = !_vm.dropdownIsOpened}}},[_c('VIcon',{staticClass:"app-languages__icon-left",attrs:{"name":"LanguageIcon","width":20,"height":20}}),_vm._v(" "),(_vm.isOpened)?_c('div',{staticClass:"app-languages__value"},[_vm._v("\n      "+_vm._s(_vm.$t('Language'))+" \n      "),_c('span',[_vm._v("\n        ("+_vm._s(_vm.selectedLanguage.name)+")\n      ")])]):_vm._e(),_vm._v(" "),(_vm.isOpened)?_c('VIcon',{class:[
          'app-languages__icon-right',
          { 'app-languages__icon-right_active': _vm.dropdownIsOpened } ],attrs:{"name":"SelectArrowIcon","height":13,"width":13}}):_vm._e()],1),_vm._v(" "),_c('VueSlideToggle',{attrs:{"open":_vm.dropdownIsOpened,"duration":300}},[_c('div',{staticClass:"app-languages__select"},_vm._l((_vm.availableLocales),function(locale){return _c('div',{key:locale.code,class:[
          'app-languages__option',
          { 'app-languages__option_collapsed': !_vm.isOpened } ],on:{"click":function($event){return _vm.selectAppLanguage(locale)}}},[(_vm.isOpened)?_c('span',[_vm._v("\n          "+_vm._s(locale.name)+"\n        ")]):_c('span',[_vm._v("\n          "+_vm._s(locale.code)+"\n        ")])])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }