//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from 'vuex';

import VIcon from '@/components/UI/VIcon';

export default {
  name: 'TheBurger',
  components: {
    VIcon,
  },

  computed: {
    ...mapState('system/toggleNavigation', [
      'isOpened',
    ]),
  },

  methods: {
    ...mapMutations('system/toggleNavigation', [
      'TOGGLE_NAVIGATION',
    ]),
  },
}
