//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VIcon from '@/components/UI/VIcon';
import VLabel from '@/components/UI/VLabel';

export default {
  name: 'Notifications',
  components: {
    VIcon,
    VLabel,
  },
}
