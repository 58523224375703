//
//
//
//
//
//
//
//
//
//
//
//
//

import HeaderLogoIcon from 'assets/svg/logo-v3.svg?inline';

export default {
  name: 'HeaderLogo',
  components: {
    HeaderLogoIcon,
  },
}
