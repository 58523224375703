//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import { VueSlideToggle } from 'vue-slide-toggle';

import VIcon from '@/components/UI/VIcon';

import { changeAppLanguage } from '@/requestManager/cabinet/main';

export default {
  name: 'AppLanguages',
  components: {
    VueSlideToggle,
    VIcon,
  },

  data: () => ({
    dropdownIsOpened: false,
    selectedLanguage: null,
  }),
  computed: {
    ...mapState('system/toggleNavigation', [
      'isOpened',
    ]),

    availableLocales() {
      return this.$i18n.locales;
    },
  },
  watch: {
    'this.selectedLanguage': {
      handler() {
        this.initDefaultLanguage();
      },
    },
  },

  created() {
    this.initDefaultLanguage();
  },

  methods: {
    closeDropdown() {
      this.dropdownIsOpened = false;
    },
    async selectAppLanguage(selectOption) {
      await changeAppLanguage(selectOption.code);
      this.selectedLanguage = selectOption;
      this.$i18n.setLocale(selectOption.code);

      this.closeDropdown();
    },

    initDefaultLanguage() {
      let i18nCookiesValue = this.$cookies.get('i18n_redirected');
      this.selectedLanguage = this.$i18n.locales.find(locale => locale.code === i18nCookiesValue);
    },
  },

  i18n: {
    messages: {
      en: {
        'Language': 'Language',
      },
      ru: {
        'Language': 'Язык',
      },
    },
  },
}
