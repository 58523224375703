//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';
import VIcon from '@/components/UI/VIcon';
import { signOutRequest } from '@/requestManager/cabinet/main';

export default {
  name: 'Account',
  components: {
    VIcon,
  },
  computed: {
    ...mapState('cabinet/profile', [
      'user',
    ]),
    ...mapGetters('cabinet/profile', [
      'mainAccountVerification',
    ]),
  },
  data() {
    return {
      defaultAvatar: require('@/assets/img/Avatar.png') 
    }
  },
  methods: {
    async triggerLogOutUser() {
      await signOutRequest(this.$cookies.get('accessToken'));
      this.$cookies.remove('accessToken');
      await this.$router.push({
        path: '/authorization/sign-in',
      });
    },
  },
  i18n: {
    messages: {
      en: {
        'Logout': 'Logout',
      },
      ru: {
        'Logout': 'Выход',
      },
    },
  },
}
