//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TheBurger from '@/components/System/Header/Burger/TheBurger';
import HeaderLogo from '@/components/System/Header/Logo/HeaderLogo';
import Notifications from '@/components/System/Header/Control/Notifications/Notifications';
import Balance from '@/components/System/Header/Control/Balance/Balance';
import Account from '@/components/System/Header/Control/Account/Account';

export default {
  name: 'TheHeader',
  components: {
    TheBurger,
    HeaderLogo,
    Notifications,
    Balance,
    Account,
  },
}
