//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';

import VIcon from '@/components/UI/VIcon';

export default {
  name: 'Balance',
  components: {
    VIcon,
  },

  computed: {
    ...mapState('cabinet/profile', [
      'user',
    ]),

    balance() {
      const balance = Number(this.user.balance).toLocaleString().replace(' ', ',');
      return balance;
    },
  },
  watch: {
    'user.demoMode': {
      async handler() {
        await this.fetchAndSetUser();
      },
    },
  },

  methods: {
    ...mapActions('cabinet/profile', [
      'fetchAndSetUser',
    ]),
  },

  i18n: {
    messages: {
      en: {
        'Demo balance': 'Demo balance',
        'Balance': 'Balance',
      },
      ru: {
        'Demo balance': 'Демо баланс',
        'Balance': 'Баланс',
      },
    },
  },
}
